/* App.css */

.App {
  width: 100vw;
  height: 100vh;
  background-color: #7699dc;
}

body {
  margin: 0;
  padding: 0;
}

.Sidebar {
  float: left; /* or float: right; depending on where you want it */
  height: 100%;
  width: 250px; /* removed the space between value and unit */
  background: linear-gradient(to top, #1e345e, #8AB9F6);
  display: flex;
  flex-direction: column;
  
}
.SidebarList
{
  height: auto;
  padding: 0;
  width: 100%;

}

.SidebarList .row 
{
  width : auto;
  height : 50px;
  list-style-type: none;
  margin : 10%;
  display : flex;
  flex-direction: row;
  color: white;
  justify-content: center;
  align-items: center;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.SidebarList .row:hover
{
  cursor: pointer;
  background-color: #1e345e;
}
.SidebarList #active
{
  background-color: #1e345e;
}
.row #icon 
{
  flex : 30%;
  display : grid;
  place-items: center;
}

.row #title 
{
  flex : 70%;
} 

.Logo img {
  width: 80%; /* adjust as needed */
  height: auto; /* adjust as needed */
  margin-bottom: 20px; /* adjust as needed */
  display: block;
  margin-left: auto;
  margin-right: auto;
}

#play-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #FFD700;
  color: #2E2E2E;
  border: none;
  padding: 15px 30px; /* Increased padding */
  font-size: 20px; /* Increased font size */
  border-radius: 8px; /* Adjusted border-radius */
  cursor: pointer;
}

#video-container {
  position: fixed;
  top: 50%;
  left: calc(50% + 125px); /* Assuming the sidebar is 250px wide */
  transform: translate(-50%, -50%);
  display: none;
  border-radius: 8px;
}